import { invoke } from '../../shared/easyfy.decorators';
import { Idependencies } from '../../shared/injectorFactory';
import { Filter } from '../../components/filter';
import { Localization } from '../../common/localization';

@invoke
class Brand {
  private locale: Localization;
  static depends: Array<Idependencies> = [
    {
      func: Localization,
      data: 'js-brand',
    },
  ];

  constructor(private localization: Localization) {
    this.locale = localization;
    Filter;
  }
}
